/*
    Convert <img src="*.svg"> to inline <svg> for improved CSS styling

    Usage:

        import { inline_all_svg } from 'mufty/js/common/inline_svg';

        // Inline all img[src$=".svg"]
        document.addEventListener('DOMContentLoaded', () => inline_all_svg());

        // Inline only those svg img which are icons
        document.addEventListener(
            'DOMContentLoaded',
            () => inline_all_svg('img[src$=".svg"].icon')
        );

        // Inline a specific svg
        import { inline_svg } from 'mufty/js/common/inline_svg';
        inline_svg(el);

    See scss/common/_inline_svg.scss for styling
*/

export const inline_svg = (el) => {
  const imgId = el.attributes["id"];
  const imgClass = el.attributes["class"];

  // Workaround for places we don't want it
  if (el.closest(".powerwiki__content") !== null) {
    return;
  }

  fetch(el.src)
    .then((response) => response.text())
    .then((raw_html) => {
      // Build HTML into object we can query
      const html = document.createRange().createContextualFragment(raw_html);

      // Get the SVG tag, ignore the rest
      const svg = html.querySelector("svg");

      // Add replaced image's ID to the new SVG
      if (imgId) {
        svg.id = imgId;
      }

      // Add replaced image's classes to the new SVG
      if (imgClass) {
        svg.className = `${imgClass} replaced-svg`;
      }

      // Remove width, height and colours so we can style them in CSS
      ["xmlns:a", "width", "height"].forEach((attr) =>
        svg.removeAttribute(attr)
      );

      const path = svg.getElementsByTagName("path")[0];
      ["fill", "style"].forEach((attr) => path.removeAttribute(attr));

      // Replace image with new SVG
      el.replaceWith(svg);
    });
};

export const inline_all_svg = (selector = 'img[src$=".svg"]') => {
  let elements = document.querySelectorAll(selector);
  elements.forEach((el) => inline_svg(el));
};
